import Text from "../../../components/Text";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../../hooks/useAuth";
import { GamePlayerDetail, GameType } from "@cjsantee/cjsantee-types/pool";

interface ScoreBoardProps {
  players: GamePlayerDetail[],
  game_type: GameType
}
function ScoreBoard({players, game_type}:ScoreBoardProps) {
  const navigate = useNavigate();
  const auth = useAuth();
  const humanPlayers = players.filter(u => u.pool_player_id);
  const numRacks = players?.[0]?.racks?.length || 0;

  function onClickPlayer(idx: number) {
    const player = players[idx];
    if(player.user_id !== auth.user?.user_id) {
      navigate(`/pool/compare/${player.pool_player_id}`);
    }
  }

  const playersBorderClass = (index: number): string => {
    // 9-Ball has the dead balls player 
    const lastPlayerIdx = players.length - (game_type === '9-Ball' ? 2 : 1);
    if(index !== lastPlayerIdx) {
      return 'border-bottom';
    }
    return '';
  }

  const lineHeightClass = (index: number): string => {
    const username = players[index].display_name;
    if(username.length > 7) {
      return 'lh-xl';
    }
    return 'lh-sm';
  }

  return (
    <div className="flex-fill d-flex flex-column justify-content-end">
      {players.length &&
        <div className="my-3">
          <div className="row">
            <div className="col-3 text-break">
              <Text size={6} position="end" className="pe-1" muted>Rack</Text>
              {game_type === '9-Ball' && (
                <Text size={6} position="end" className="pe-1" muted>DB</Text>
              )}
              {humanPlayers.map((player, idx) => (
                <Text key={`player-${idx}-username`}
                  size={5} 
                  className={`py-1 pe-1 ${playersBorderClass(idx)} lh-sm`}
                  onClick={() => onClickPlayer(idx)}>{player.display_name}</Text>
              ))}
            </div>
            <div className="col-7 row flex-nowrap overflow-scroll no-scrollbar">
              {new Array(numRacks).fill(0).map((_, rackIdx) => (
                <div key={`rack-${rackIdx}-number`} className="d-flex flex-column w-auto min-w-2rem">
                  <Text size={6} position="center" muted>{rackIdx + 1}</Text>
                  {game_type === '9-Ball' && (
                    // Deadballs
                    <Text size={6} position="center" muted>{players[players.length - 1].racks[rackIdx]}</Text>
                  )}
                  {humanPlayers.map((player, playerIdx) => (
                    <Text key={`rack-score-${playerIdx}`} size={5} position="center" className={`px-2 py-1 ${playersBorderClass(playerIdx)} ${lineHeightClass(playerIdx)}`}>{player.racks[rackIdx]}</Text>
                  ))}
                </div>
              ))}
              <div className="d-flex flex-column w-100">
                <Text size={6} hide>.</Text>
                {game_type === '9-Ball' && (
                  <Text size={6} hide>.</Text>
                )}
                {humanPlayers.map((_, pIdx) => (
                  <Text key={`period-${pIdx}`} size={5} className={`px-2 py-1 ${playersBorderClass(pIdx)} ${lineHeightClass(pIdx)}`}>
                    <span className="opacity-0">.</span>
                  </Text>
                ))}
              </div>
            </div>
            <div className="col-2">
              <Text size={6} hide>.</Text>
              {game_type === '9-Ball' && (
                <Text size={6} hide>.</Text>
              )}
              {humanPlayers.map((player, idx) => (
                <div key={`handicap-${idx}`} className="d-flex w-100 justify-content-end align-items-end">
                  <Text size={5} className={`py-1 w-100 ${playersBorderClass(idx)} ${lineHeightClass(idx)}`}>
                    <span className="opacity-0">.</span>
                  </Text>
                  <Text size={6} position="end" className={`py-1 ${playersBorderClass(idx)} ${lineHeightClass(idx)}`} muted>
                    ({player.handicap})
                  </Text>
                </div>
              ))}
            </div>
          </div>
        </div>
      }
    </div>
  )
}

export default ScoreBoard;
