import { useEffect, useState } from "react"
import api from "../../../utils/api";
import { StoredLogDB } from "@cjsantee/cjsantee-types";

export default function Logs() {
  const [logs, setLogs] = useState<StoredLogDB[]>([]);

  useEffect(() => {
    const getLogs = async () => {
      const { data, success } = await api.get('/logs');
      if(success) {
        const { logs: server_logs } = data;
        setLogs(server_logs);
      }
    }
    getLogs();
  }, []);

  return (
    <div className="container">
      <ul className="list-group">
        {logs.map((log) => (
          <LogRow key={log.stored_log_id} log={log} />
        ))}
      </ul>
    </div>
  );
}

interface LogRowProps {
  log: StoredLogDB;
}
function LogRow({log}:LogRowProps) {
  const createdStr = new Date(log.created).toLocaleString('en-us', {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    minute: '2-digit',
    hour: '2-digit',
  });

  const textColor = () => {
    switch(log.log_level.toLowerCase()) {
      case 'debug':
        return 'secondary';
      case 'info':
        return 'info';
      case 'warn':
        return 'warning';
      case 'error':
        return 'danger';
    }
  }

  return (
    <li className="bg-card list-group-item d-flex flex-column">
      <span className={`d-flex w-100 justify-content-center fw-bold text-${textColor()}`}>{createdStr}</span>
      {log.username && <span className="fw-bold">{log.username}</span>}
      <p className="m-0 small">{log.message}</p>
    </li>
  );
}